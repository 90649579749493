body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-page-layout {
  padding: 36px 48px 0 48px;
  height: 100%;
  flex-wrap: nowrap;
}

.flex-section {
  flex-grow: 1;
  display: flex;
  min-height: 0;
  flex-wrap: nowrap;
}

.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}

.label {
  font-weight: bold !important;
  white-space: nowrap;
}